<template>
    <div id="app">
        <div v-if="promo !== null" class="notification-bar">
            <p class="is-size-5 has-text-white has-text-weight-bold"><a @click="promoClick" class="has-text-white">{{ promo }} →</a></p>
        </div>

        <Modal v-if="showPromoModal">
            <h1 class="title has-text-white is-size-4" style="margin-bottom: 5px;">👋 Summer sale!</h1>
            <p class="has-text-grey-light is-size-5" style="padding-bottom: none;">For a limited time use code <span class="has-text-weight-bold has-text-white">SUMMER</span> during checkout for <span class="has-text-weight-bold has-text-white">25%</span> off your first month of premium!</p>

            <div class="buttons is-centered" style="margin-top: 15px;">
                <button class="button is-light" @click="denyOffer">Dismiss</button>
                <button class="button is-primary" @click="acceptOffer">Yes please!</button>
            </div>
        </Modal>

        <Migrate v-if="needsMigration"/>
        <PremiumPopup v-if="discoverPremium && !limit" />
        <PremiumPopupLimited v-if="discoverPremium && limit" />

        <Navbar :route="$router.currentRoute.name" />
        <div class="container" v-if="showAlert.length > 0">
            <br>
        </div>

        <router-view v-if="ratelimited === false" />
        <!-- <ratelimited v-if="ratelimited" /> -->

        <Footer :route="$router.currentRoute.name" :ratelimited="ratelimited" />
    </div>
</template>

<script>
import Modal from '@/components/Modal'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import PremiumPopup from '@/components/PremiumPopup'
import PremiumPopupLimited from '@/components/PremiumPopupLimited'
import Ratelimited from '@/components/Ratelimited'
import Migrate from '@/components/Migrate'
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
    metaInfo: {
      title: 'The Best Discord Bot',
      titleTemplate: '%s | Arcane.bot'
    },
    data() {
        return {
            showAlert: [],
            ratelimited: false,
            promo: 'Summer sale: Use code SUMMER for 25% off your first month',

            showPromoModal: false,
        };
    },
    components: {
        Modal,
        Navbar,
        Footer,
        Ratelimited,
        PremiumPopup,
        PremiumPopupLimited,
        Migrate,
    },
    async mounted() {
        if (localStorage.getItem('2023.8.21-trial-promotion')) {
            this.promo = null;
        }

        if (!localStorage.getItem('2024.7.11.summer')) {
            setTimeout(() => {
                this.showPromoModal = true;
            }, 25 * 1000);
        }

        const logoutUser = this.logoutUser;
        const t = this;

        this.$axios.interceptors.response.use(undefined, function (err) {
            return new Promise(function (resolve, reject) {
                if (err.response.status === 401 && err.response.data.forceLogout === true && err.response.config && !err.response.config.__isRetryRequest) {
                    logoutUser();
                    window.location = `${process.env.VUE_APP_BASE_URL}/login`
                } else if (err.response.status === 401 && !err.response.data.forceLogout && err.response.config && !err.response.config.__isRetryRequest) {
                    window.location = `${process.env.VUE_APP_BASE_URL}/dashboard`
                }
                throw err;
            });
        });

        const token = localStorage.getItem('arcane_token');
        if (token) {
            this.authenticating(true);
            let data = await this.$axios.get(`/oauth/user`, { headers: { Authorization: token }});
            this.authenticating(false)
            if (data.data.error) this.logoutUser();
            else this.userAuthenticated(data.data);
        } else this.logoutUser();
    },
    computed: {
        ...mapState(['user', 'discoverPremium', 'limit', 'needsMigration']),
    },
    methods: {
        ...mapActions(['logoutUser']),
        ...mapMutations(['userAuthenticated', 'authenticating', 'setExperiments']),
        promoClick() {
            // localStorage.setItem('2023.7.24-promotion', '1');

            this.$router.push('/premium')
        },
        acceptOffer() {
            this.$router.push('/premium');

            this.showPromoModal = false;
            localStorage.setItem('2024.7.11.summer', '1');
        },
        denyOffer() {
            this.showPromoModal = false;
            localStorage.setItem('2024.7.11.summer', '1');
        }
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- background: #2C2F33 -->
<!-- background: linear-gradient(0deg, rgb(23, 24, 30), rgb(23, 24, 30)), linear-gradient(90deg, #41B2B0 0%, rgb(255, 209, 189) 100%), rgb(23, 24, 30); -->

<style @lang="scss">
@import 'assets/css/main.css';

.notification-bar {
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to right, #ffb6c1, #C86DD7, #41B2B0, #3B7B80);
  text-align: center;
  color: #FFFFFF;
  padding-top: 12px;
  padding-bottom: 12px;
}

.notification-bar:hover {
    color: #5EDCDC;
}

footer {
    position: relative;
    width: 100%;
    padding-top: 75px;
    padding-bottom: 37.5px;
}

.multiselect .multiselect__single {
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
    font-weight: 900 !important;
}

.multiselect__tag {
    background: #41B2B0;
    font-family: inherit;
    font-weight: 600;
}

.multiselect__tag-icon::after {
    color: white;
}

.multiselect__option {
    color: black !important;
}

.multiselect__tag-icon:hover {
    background: #41B2B0;
}

.multiselect__input, .multiselect__single {
    padding: 0 0 0 0;
}

.multiselect__placeholder {
    margin-left:4px;
    color: #999999;
} 

a.link:hover {
    color: #3ea9a7 !important;
}

.nav-link {
    color: white;
    margin-bottom: 5px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
    margin-top: 5px !important;
}
.nav-link:hover {
    color: #ddd !important;
}
.content.unstyled ul{
    padding: 0;
    margin: 0;
}
.content.unstyled ul li{
    list-style-type: none;
    padding-top: 5px;
}


.router-link.link:hover {
    color: #3ea9a7 !important;
}

.custom-toast {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    background-color:#2a2d32 !important;
    border-radius: 8px !important;
    box-shadow: 0px 3px 10px -3px black !important;
}

@media only screen and (max-width: 600px) {
    .custom-toast {
        width: 100% !important;
    }
}

@media only screen and (min-width: 600px) {
    .custom-toast {
        width: 50vw !important;
    }
}

.ad {
    height: 90px;
    width: 728px;
}


:focus {
    outline:none;
}
::-moz-focus-inner {
    border:0;
}


.alert-1 {
    display: flex; 
    position: fixed; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; 
    padding-left: 1rem;
    padding-right: 1rem; 
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; 
    margin-bottom: 6rem; 
    justify-content: center; 
    align-items: flex-end; 
    pointer-events: none; 

    z-index: 1000;
}

.alert-2 {
    display: flex; 
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; 
    padding-left: 1rem;
    padding-right: 1rem; 
    background-color: #000000; 
    color: #ffffff; 
    justify-content: space-between; 
    align-items: center; 
    border-radius: 0.5rem; 
    pointer-events: auto; 
    gap: 2rem; 
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25); 
}

@media (min-width: 1024px) { 
    .alert-2 {
        gap: 14rem; 
    }
}

.alert-3 {
    display: flex; 
    align-items: center; 
    gap: 1.25rem; 
}

.alert-reset {
    cursor: pointer; 
}
.alert-reset:hover {
    color: #D1D5DB; 
}

.alert-save {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; 
    padding-left: 0.75rem;
    padding-right: 0.75rem; 
    /* background-color: #059669;  */
    color: #ffffff; 
    border-radius: 0.375rem; 
}

.selectable-pill {
    background-color: white;
    color: black;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 5px;
    cursor: pointer;
}
</style>
